<template>
  <sub-page
      :title="$t('Documents')"
      v-model="$store.state.settings.pageData"
      :header-action="openNewDocument"
      :header-action-condition="items.length > 0"
      :header-action-text="$t('documentflow.document.add')"
      icon="mdi-file-document-multiple-outline"

      :no-content-condition="items.length === 0"
      :no-content-button-action="openNewDocument"
      no-content-button-text="documentflow.document.add"
      no-content-text="documentflow.document.no_content"
      fill-height

      :filters="filtersSelect"
  >
    <template #default>
      <ws-data-table
          class="mt-5"
          :items="itemsFiltered"
          :headers="headers"
          :search="$store.state.settings.filtersData.search"
          :row-action="($event) => downloadFile($event.file_data)"
      >

        <template #item.date="{item}">
          <h5 class="text-no-wrap">{{ MONTH_DAY_TIME(item.date , false) }}</h5>
        </template>

        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
        </template>

        <template #item.code="{item}">
          <h5 class="text-no-wrap" >{{ item.code }}</h5>
        </template>

        <template #item.is_signed="{item}">
          <div class="d-flex justify-center">
            <v-icon :color="item.is_signed ? wsACCENT : null">mdi-check-decagram</v-icon>
          </div>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>
      <ws-dialog
          v-if="displayDialog"
          @save="addEditDocument"
          v-model="displayDialog"
          :title="newEntity ? $t('documentflow.document.add') : $t('documentflow.document.edit')"
          width="800"
      >
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
            clearable
            :error="nameError"
            @input="nameError = null"
        />
        <v-row no-gutters class="mt-5" >
          <v-col cols="3" class="pr-1">
            <ws-select
                v-model="entityData.type"
                :label="$t('Type')"
                :placeholder="$t('Choose')"
                :items="documentTypesSelect"
                :error="typeError"
                @input="typeError = null"
            />
          </v-col>
          <v-col cols="6" class="pl-1">
            <ws-text-field
                v-model="entityData.code"
                :label="$t('documentflow.document.code')"
                :placeholder="$t('documentflow.document.enter_code')"
                clearable
                :error="codeError"
                @input="codeError = null"
            />
          </v-col>
          <v-col cols="3" class="pl-1">
            <ws-date-picker
                v-model="entityData.date"
                :label="$t('Date')"
                :placeholder="$t('ChooseDate')"
                time-stamp
                :error="dateError"
                @input="dateError = null"
            />
          </v-col>
        </v-row>



        <h4 class="mb-3">{{ $t('Signators') }}</h4>
        <div v-for="userId in entityData.signators"  :key="userId">
          <div
              class="d-flex justify-space-between align-center "
              style="width : 100%"
          >

            <div class="d-flex align-center">
              <v-avatar class="mr-3" size="40" :color="wsLIGHTCARD">
                <v-icon v-if="!getUserName(userId).img" dark>mdi-account</v-icon>
                <v-img v-else :src="getUserName(userId).img"></v-img>
              </v-avatar>
              <div>
                <h5 :style="`color : ${wsACCENT}`">{{ getUserName(userId) }} </h5>
                <h6>{{ getUserName(userId).email }} </h6>
              </div>

            </div>
            <v-btn @click="removeSignator(userId)" icon small>
              <v-icon small>mdi-close</v-icon>
            </v-btn>

          </div>
          <v-divider :style="`border-color : ${wsBACKGROUND}`" class="my-3" />
        </div>

        <ws-select
            :key="updateTrigger"
            v-if="managersSelectFiltered.length > 0"
            :class="[{'pt-5' :entityData.signators.length > 0 }]"
            @input="addSignator"
            :value="newSignator"
            :items="managersSelectFiltered"
            :label="$t('documentflow.signature.add_signator')"
            :placeholder="$t('documentflow.signature.choose_signator')"
            autocomplete
            :error="signatorError"
        >
        </ws-select>

        <ws-file-uploader
            v-if="!entityData.file"
            class="mt-5"
            @success="addFile"
        />
        <v-sheet v-else class="d-flex justify-space-between align-center pa-3" :style="`border : 1px solid ${wsBACKGROUND}`" >
          <div class="d-flex align-center">
            <v-icon class="mr-2" :color="wsACCENT">mdi-file</v-icon>
            <h5 :style="`color : ${wsACCENT}`" class="shorten-text"> {{ entityData.file_name }}</h5>
          </div>
          <v-btn @click="deleteFile" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-sheet>

        <h5 v-if="fileError" :style="`color : ${wsWARNING}`">{{ $t('ChooseFile') }}</h5>


      </ws-dialog>
    </template>


  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Tasks",
  data() {
    return {
      items : [],
      displayDialog : false,
      entityData : {
        signators : []
      },
      documentTypesSelect : [],
      managersSelect : [],
      newEntity : false,
      newSignator : null,
      updateTrigger : 0,
      file : {},

      nameError : null,
      typeError : null,
      codeError : null,
      dateError : null,
      signatorError : null,
      fileError : null,
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.COPY(this.items)

      if ( this.$store.state.settings.filtersData.type ) {
        items = items.filter(el => el.type === this.$store.state.settings.filtersData.type )
      }
      if ( [true,false].includes( this.$store.state.settings.filtersData.is_signed ) ) {
        items = items.filter(el => el.is_signed === this.$store.state.settings.filtersData.is_signed )
      }

      return items
    },
    headers() {
      return [
        { text : this.$t('Date') , value : 'date' , width : 10 },
        { text : this.$t('Type') , value : 'type_name' , width : 10 },
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Code') , value : 'code' , width : 10 },
        { text : this.$t('IsSigned') , value : 'is_signed' , width : 10 }
      ]
    },
    managersSelectFiltered() {
      let items = this.COPY(this.managersSelect)
      if ( !this.entityData.signators ) {
        return items
      }
      return items.filter(el => !this.entityData.signators.includes(el.value) || this.newSignator === el.value)
    },
    filtersSelect(){
      return [
        { text : this.$t('Type')  , value : 'type' , type : 'select' , items : this.documentTypesSelect },
        { text : this.$t('IsSigned')  , value : 'is_signed' , type : 'select' , items : this.CHOICE_ACTIVE_SELECT },
      ]
    },
  },
  methods : {
    ...mapActions('documentflow', [
      'GET_DOCUMENTS',
      'ADD_EDIT_DOCUMENT',
    ]),

    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    async downloadFile(item) {

      let result = await this.GET_PRIVATE_FILE(item.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    getUser(value) {
      let user = this.managersSelect.find(el => el.value === value)
      if ( user ) {
        return user
      }
      return {}
    },
    getUserName(value) {
      let user = this.managersSelect.find(el => el.value === value)
      if ( user ) {
        return user.text
      }
      return ''
    },
    removeSignator(value) {
      this.updateTrigger++
      this.entityData.signators = this.entityData.signators.filter(el => el !== value)
    },
    addSignator(value) {
      this.signatorError = null
      this.entityData.signators.push(value)
      this.newSignator = null
      this.updateTrigger++
    },
    deleteFile() {
      this.file = {}
      this.entityData.file = null
      this.entityData.file_name = null
    },
    addFile(file) {
      this.fileError = null
      this.file = file
      this.entityData.file = file.uuid
      this.entityData.file_name = file.name
      this.entityData = this.COPY(this.entityData)
    },
    async addEditDocument() {

      if ( !this.entityData.name ) {
        this.nameError = true
        this.notify(this.$t('documentflow.document.name_error') , 'warning')
        return
      }
      if ( !this.entityData.type ) {
        this.typeError = true
        this.notify(this.$t('documentflow.document.type_error') , 'warning')
        return
      }
      if ( !this.entityData.code ) {
        this.codeError = true
        this.notify(this.$t('documentflow.document.code_error') , 'warning')
        return
      }

      if ( !this.entityData.date ) {
        this.dateError = true
        this.notify(this.$t('documentflow.document.date_error') , 'warning')
        return
      }

      if ( this.entityData.signators.length === 0 ) {
        this.signatorError = true
        this.notify(this.$t('documentflow.document.signator_error') , 'warning')
        return
      }

      if ( !this.entityData.file) {
        this.fileError = true
        this.notify(this.$t('documentflow.document.file_error') , 'warning')
        return
      }




      let result = await this.ADD_EDIT_DOCUMENT(this.entityData)
      if ( !result )  {
        this.ERROR()
      }
      if ( this.newEntity) {
        this.items.unshift(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid)
        this.items[index] = this.COPY(result)
        this.items = this.COPY(this.items)
      }
      this.displayDialog = false
    },
    openNewDocument() {
      this.nameError = null
      this.typeError = null
      this.codeError = null
      this.dateError = null
      this.signatorError = null
      this.fileError = null

      this.newSignator = null
      this.newEntity = true
      this.entityData = this.COPY( {
        signators : []
      })
      this.displayDialog = true
    },
    openEditDocument(item) {
      this.nameError = null
      this.typeError = null
      this.codeError = null
      this.dateError = null
      this.signatorError = null
      this.fileError = null

      this.newEntity = false
      this.entityData = this.COPY(item)
      this.displayDialog = true
    },

    // technical

    async initPage() {
      let result = await this.GET_DOCUMENTS()
      if ( !result )  {
        this.ERROR()
      }
      result.items.reverse()
      this.items = result.items
      this.documentTypesSelect = result.document_types_select
      this.managersSelect = result.managers_select
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>